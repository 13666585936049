import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface BxAppInfo {
  lineId: string;
  lineUrl: string;
  lineQrCodeUrl: string;
  message1: string;
  message2: string;
  appLoaded: boolean;
}

export interface BxMemberInfo {
  credit: BxCreditInfo;
  sponsor: BxSponsorInfo;
  turn_over: BxTurnOverInfo;
  position: null | number;
}

export interface BxCreditInfo {
  free_credit: number;
  free_commission: number;
  wheel_commission: number;
  box_commission: number;
  million_commission: number;
  continuous_bonus: number;
  wheel_spin: number;
  free_spin: number;
  free_lotto: number;
  nowLoading: boolean;
  min_transfer: number;
}

export interface BxSponsorInfo {
  ref_id: string;
  ref_name: string;
}
export interface BxSeoInfo {
  ref_id: string;
}
export interface BxTurnOverInfo {
  promotionName: string;
  turnOver: number;
  accumulateTurnOver: number;
  requiredTurOver: number;
  turnFromDate: Date;
  promotionMethod: number;
  turnOverFromType: number;
}

@Module
export default class MemberModule
  extends VuexModule
  implements BxAppInfo, BxMemberInfo
{
  lineId = "";
  lineUrl = "";
  lineQrCodeUrl = "";
  appLoaded = false;
  message1 = "";
  message2 = "";

  loadingCredit = false;

  credit = {} as BxCreditInfo;
  turn_over = {} as BxTurnOverInfo;
  sponsor = {} as BxSponsorInfo;
  seo = {} as BxSeoInfo;
  position = null;
  //appLoaded = false;
  //free_credit = 0;
  //free_commission = 0;
  //ref_id = "";
  //ref_name = "";
  //sponsor = {} as BxSponsorInfo;
  //turn_over = {} as BxTurnOverInfo;
  get currentCredit(): BxCreditInfo {
    return this.credit;
  }
  get currentFreeCredit(): number {
    return this.credit.free_credit;
  }
  get currentFreeCommission(): number {
    return this.credit.free_commission;
  }
  get currentCompanyLineId(): string {
    return this.lineId;
  }
  get currentCompanyLineUrl(): string {
    return this.lineUrl;
  }
  get currentLineQrCodeUrl(): string {
    return this.lineQrCodeUrl;
  }
  get loginMessage1(): string {
    return this.message1;
  }
  get loginMessage2(): string {
    return this.message2;
  }
  get isAppLoaded(): boolean {
    return this.appLoaded;
  }

  get sponsorId(): string {
    //console.log("getsp" + this.sponsor.ref_id);
    if (this.sponsor?.ref_id) return this.sponsor.ref_id;
    return window.sessionStorage.getItem(this.ID_SP_KEY) as string;
  }
  get sponsorName(): string {
    return this.sponsor.ref_name;
  }

  get seoId(): string {
    if (this.seo?.ref_id) return this.seo.ref_id;
    return window.sessionStorage.getItem(this.ID_SR_KEY) as string;
  }

  get turnOver(): BxTurnOverInfo {
    return this.turn_over;
  }

  get minTransfer(): number {
    return this.credit.min_transfer;
  }

  ID_SR_KEY = "sr" as string;
  ID_SP_KEY = "sp" as string;

  @Action
  [Actions.GET_APPINFO]() {
    return ApiService.get("authentication/appInfo")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BX_APPINFO_MUTATION, {
          lineId: data.lineId,
          lineUrl: data.lineUrl,
          lineQrCodeUrl: data.qrCodeUrl,
          message1: data.message1,
          message2: data.message2,
        });
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.CHECK_USERCREDIT]() {
    if (this.credit.nowLoading) {
      return;
    }
    this.credit.nowLoading = true;
    ApiService.setHeader();
    return ApiService.get("account/credit")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BX_USERCREDIT_MUTATION, {
          free_credit: data.credit,
          free_commission: data.commission,
          wheel_commission: data.wheelCommission,
          box_commission: data.boxCommission,
          million_commission: data.millionCommission,
          continuous_bonus: data.continuousBonus,
          wheel_spin: data.wheelSpin,
          diamond: data.diamond,
          free_spin: data.freeSpin,
          free_lotto: data.freeLotto,
          min_transfer: data.minTransfer,
        });
        this.context.commit(Mutations.SET_ERROR, {});
        this.credit.nowLoading = false;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
        this.credit.nowLoading = false;
      });
  }

  @Action
  [Actions.CHECK_TURNOVER_ACTION]() {
    ApiService.setHeader();
    return ApiService.get("account/turnover")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BX_TURNOVER_MUTATION, data);
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CHECK_USERINFO_ACTION]() {
    ApiService.setHeader();
    return ApiService.get("account/dashboard")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BX_USERCREDIT_MUTATION, data.credit);
        this.context.commit(Mutations.SET_BX_TURNOVER_MUTATION, data.turnOver);
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Mutation
  [Mutations.SET_BX_APPINFO_MUTATION](payload) {
    this.lineId = payload.lineId;
    this.lineUrl = payload.lineUrl;
    this.lineQrCodeUrl = payload.lineQrCodeUrl;
    this.message1 = payload.message1;
    this.message2 = payload.message2;
    if (payload.lineId) {
      this.appLoaded = true;
    }
  }
  @Mutation
  [Mutations.SET_BX_USERCREDIT_MUTATION](payload) {
    this.credit = payload;
    //this.free_credit = payload.free_credit;
    //this.free_commission = payload.free_commission;
  }

  @Mutation
  [Mutations.SET_BX_SPONSOR_MUTATION](payload) {
    //console.log("setsp" + payload.ref_id);
    this.sponsor = payload;
    window.sessionStorage.setItem(this.ID_SP_KEY, payload.ref_id);
    window.sessionStorage.removeItem(this.ID_SR_KEY);
    //console.log("setsp" + this.sponsor.ref_id);
    //this.ref_id = payload.ref_id;
    //this.ref_name = payload.ref_name;
  }

  @Mutation
  [Mutations.SET_BX_SEO_MUTATION](payload) {
    this.seo = payload;
    window.sessionStorage.setItem(this.ID_SR_KEY, payload.ref_id);
    window.sessionStorage.removeItem(this.ID_SP_KEY);
    //this.ref_id = payload.ref_id;
    //this.ref_name = payload.ref_name;
  }

  @Mutation
  [Mutations.SET_BX_TURNOVER_MUTATION](payload) {
    this.turn_over = payload;
    //this.ref_id = payload.ref_id;
    //this.ref_name = payload.ref_name;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    //this.lineId = "";
    //this.lineUrl = "";
    //this.appLoaded = false;
    this.credit = {} as BxCreditInfo;
    this.turn_over = {} as BxTurnOverInfo;
    //this.sponsor = {} as BxSponsorInfo;
  }
}
